export default {
  item: {
    type: 'reference',
    required: true,
  },
  objective: {
    type: 'reference',
    required: true,
  },
};
