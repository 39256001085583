/* eslint no-bitwise: 0 */

/**
 * cyrb53 (c) 2018 bryc (github.com/bryc)
 *
 * License: Public domain.
 *
 * A fast and simple 53-bit string hash function with decent collision
 * resistance.
 *
 * Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
 */
function simpleHash(str, seed = 0) {
  let h1 = 0xdeadbeef ^ seed;
  let h2 = 0x41c6ce57 ^ seed;

  for (let i = 0, ch; i < str.length; i += 1) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }

  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);

  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export default simpleHash;
