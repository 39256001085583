<template>
  <svg
    :class="['buildings', `buildings--${skin}`]"
    viewBox="0 0 1382 280"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1295.86 152.227H1238.45H1209.73V94.7492V0H1295.86V152.227Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M1039.93 279.902H995.629H973.48V174.202V0H1039.93V279.902Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1126.06 279.902H1068.65H1039.93V174.202V0H1126.06V279.902Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M1158.05 279.902H1084.22H1047.31V229.839V147.317H1158.05V279.902Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1382 279.902H1232.69H1158.05V229.839V147.317H1382V279.902Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M1209.73 147.317H1165.43H1143.29V91.68V0H1209.73V147.317Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1032.54 122.764H1020.24V135.04H1032.54V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 122.764H1000.55V135.04H1012.86V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 122.764H980.863V135.04H993.168V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1032.54 149.772H1020.24V162.048H1032.54V149.772Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 149.772H1000.55V162.048H1012.86V149.772Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 149.772H980.863V162.048H993.168V149.772Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1032.54 176.78H1020.24V189.057H1032.54V176.78Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 176.78H1000.55V189.057H1012.86V176.78Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 176.78H980.863V189.057H993.168V176.78Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1032.54 203.788H1020.24V216.064H1032.54V203.788Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 203.788H1000.55V216.064H1012.86V203.788Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 203.788H980.863V216.064H993.168V203.788Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1032.54 230.796H1020.24V243.073H1032.54V230.796Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 230.796H1000.55V243.073H1012.86V230.796Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 230.796H980.863V243.073H993.168V230.796Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M993.168 78.5693H980.863V98.2115H993.168V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1012.86 78.5693H1000.55V98.2115H1012.86V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1032.54 78.5693H1020.24V98.2115H1032.54V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1118.68 122.764H1106.37V135.04H1118.68V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1098.99 122.764H1086.68V135.04H1098.99V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1079.3 122.764H1067V135.04H1079.3V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1059.61 122.764H1047.31V135.04H1059.61V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1059.61 78.5693H1047.31V98.2115H1059.61V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1079.3 78.5693H1067V98.2115H1079.3V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1098.99 78.5693H1086.68V98.2115H1098.99V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1118.68 78.5693H1106.37V98.2115H1118.68V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1128.52 247.983H1116.21V265.17H1128.52V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1148.21 228.341V211.154H1135.9V228.341H1128.52V211.154H1116.22V228.341H1108.83V211.154H1096.53V228.341H1089.14V211.154H1076.84V228.341H1069.46V211.154H1057.15V228.341H1047.31V235.707H1158.05V228.341H1148.21Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1148.21 247.983H1135.9V265.17H1148.21V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1108.83 247.983H1096.53V265.17H1108.83V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1089.14 247.983H1076.84V265.17H1089.14V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1069.46 247.983H1057.15V265.17H1069.46V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1382 147.317H1158.05V154.683H1382V147.317Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1374.62 228.341V211.154H1362.31V228.341H1354.93V211.154H1342.62V228.341H1335.24V211.154H1322.94V228.341H1315.55V211.154H1303.25V228.341H1295.86V211.154H1283.56V228.341H1276.18V211.154H1263.87V228.341H1256.49V211.154H1244.18V228.341H1236.8V211.154H1224.5V228.341H1217.11V211.154H1204.81V228.341H1197.43V211.154H1185.12V228.341H1177.74V211.154H1165.43V228.341H1158.05V235.707H1382V228.341H1374.62Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1236.8 247.983H1224.5V265.17H1236.8V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1217.12 247.983H1204.81V265.17H1217.12V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1197.43 247.983H1185.12V265.17H1197.43V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1177.74 247.983H1165.43V265.17H1177.74V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1315.55 247.983H1303.25V265.17H1315.55V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1295.87 247.983H1283.56V265.17H1295.87V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1276.18 247.983H1263.88V265.17H1276.18V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1256.49 247.983H1244.19V265.17H1256.49V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1374.62 247.983H1362.31V265.17H1374.62V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1354.93 247.983H1342.62V265.17H1354.93V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1335.24 247.983H1322.94V265.17H1335.24V247.983Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1202.35 122.764H1190.04V135.04H1202.35V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1182.66 122.764H1170.36V135.04H1182.66V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1162.97 122.764H1150.67V135.04H1162.97V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1162.97 78.5693H1150.67V98.2115H1162.97V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1182.66 78.5693H1170.36V98.2115H1182.66V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1202.35 78.5693H1190.04V98.2115H1202.35V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1288.48 122.764H1276.18V135.04H1288.48V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1268.8 122.764H1256.49V135.04H1268.8V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1249.11 122.764H1236.8V135.04H1249.11V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1229.42 122.764H1217.12V135.04H1229.42V122.764Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1229.42 78.5693H1217.12V98.2115H1229.42V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1249.11 78.5693H1236.8V98.2115H1249.11V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1268.8 78.5693H1256.49V98.2115H1268.8V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1288.48 78.5693H1276.18V98.2115H1288.48V78.5693Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1250.34 58.9262C1261.89 58.9262 1271.25 49.5825 1271.25 38.0564C1271.25 26.5303 1261.89 17.1865 1250.34 17.1865C1238.78 17.1865 1229.42 26.5303 1229.42 38.0564C1229.42 49.5825 1238.78 58.9262 1250.34 58.9262Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M1251.57 27.0083H1246.65V31.9188H1251.57V27.0083Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1251.57 31.9189H1246.65V36.8295H1251.57V31.9189Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1251.57 36.8291H1246.65V41.7396H1251.57V36.8291Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M1256.49 36.8291H1251.57V41.7396H1256.49V36.8291Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M305.156 206.341L280.547 230.894V280H329.766V230.894L305.156 206.341Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M255.938 230.894V255.447H231.328V280H255.938H265.043H280.547V264.801V255.447V230.894H255.938Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M354.375 255.447H329.766V280H354.375V255.447Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M378.985 230.894L354.375 255.447V280H378.985H403.594V255.447L378.985 230.894Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M428.207 240.715H403.598V280H428.207V240.715Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M452.813 216.162L428.203 240.715V279.999H452.813H477.422V240.715L452.813 216.162Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M280.547 230.894H255.938L280.547 206.341H305.157L280.547 230.894Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M231.328 255.447H255.938V230.894L231.328 255.447Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M312.539 270.179H302.695V280H312.539V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M354.375 255.447H329.766L354.375 230.894H378.985L354.375 255.447Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M383.906 270.179H374.062V280H383.906V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M428.207 240.715H403.598L428.207 216.162H452.817L428.207 240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M457.734 270.179H447.891V280H457.734V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M152.579 196.52H108.281L132.891 171.967H177.188L152.579 196.52Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M152.579 196.521H108.281V280H152.579V196.521Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M177.188 171.967L152.578 196.52V280H177.188H201.797V196.52L177.188 171.967Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M182.109 270.179H172.266V280H182.109V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M172.266 252.992H162.422V262.813H172.266V252.992Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M191.957 252.992H182.113V262.813H191.957V252.992Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M172.266 233.35H162.422V243.171H172.266V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M191.957 233.35H182.113V243.171H191.957V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M172.266 213.707H162.422V223.528H172.266V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M191.957 213.707H182.113V223.528H191.957V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M135.352 243.17H125.508V262.813H135.352V243.17Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M135.352 213.707H125.508V233.349H135.352V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M49.2192 137.594H0V280H49.2192V137.594Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M108.282 137.594H49.2188V280H108.282V137.594Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M83.672 270.179H73.8281V280H83.672V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M73.8282 233.35H63.9844V243.171H73.8282V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M93.5158 233.35H83.6719V243.171H93.5158V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M73.8282 213.707H63.9844V223.528H73.8282V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M93.5158 213.707H83.6719V223.528H93.5158V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M73.8282 194.065H63.9844V203.887H73.8282V194.065Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M93.5158 194.065H83.6719V203.887H93.5158V194.065Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M73.8282 174.423H63.9844V184.244H73.8282V174.423Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M93.5158 174.423H83.6719V184.244H93.5158V174.423Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M73.8282 154.781H63.9844V164.602H73.8282V154.781Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M93.5158 154.781H83.6719V164.602H93.5158V154.781Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M29.5312 243.17H19.6875V262.813H29.5312V243.17Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M29.5312 213.707H19.6875V233.349H29.5312V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M29.5312 184.244H19.6875V203.886H29.5312V184.244Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M29.5312 154.781H19.6875V174.423H29.5312V154.781Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 157.236V147.415H627.543V137.594H617.699V147.415H607.856V157.236H598.012V280H647.231V157.236H637.387Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M607.856 137.594H605.985H598.012V147.415H596.609H588.168V157.236H596.609H598.012H607.856V147.415H617.699V137.594H607.856Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M506.957 206.341V255.447V280H531.567V255.447V206.341H506.957Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M556.176 181.789L531.566 206.342V280H580.786V206.342L556.176 181.789Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M531.567 206.342H506.957L531.567 181.789H556.176L531.567 206.342Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M563.559 270.179H553.715V280H563.559V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M551.254 213.707H541.41V223.528H551.254V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M570.942 213.707H561.098V223.528H570.942V213.707Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M551.254 233.35H541.41V243.171H551.254V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M570.942 233.35H561.098V243.171H570.942V233.35Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M627.543 270.179H617.699V280H627.543V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 250.537H627.543V260.358H637.387V250.537Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M617.699 250.537H607.855V260.358H617.699V250.537Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 240.715H627.543V250.536H637.387V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M617.699 240.715H607.855V250.536H617.699V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 221.073H627.543V230.894H637.387V221.073Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M617.699 221.073H607.855V230.894H617.699V221.073Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 211.252H627.543V221.074H637.387V211.252Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M617.699 211.252H607.855V221.074H617.699V211.252Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M637.387 191.61H627.543V201.431H637.387V191.61Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M617.699 191.61H607.855V201.431H617.699V191.61Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M598.012 157.235H580.785V279.999H598.012V157.235Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M622.621 181.788C629.417 181.788 634.926 176.292 634.926 169.512C634.926 162.732 629.417 157.235 622.621 157.235C615.825 157.235 610.316 162.732 610.316 169.512C610.316 176.292 615.825 181.788 622.621 181.788Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M718.008 230.894V157.236L693.399 132.683L668.789 157.236V280H700.388H718.008H816.447V230.894H718.008Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M816.446 198.976V230.894H767.227H753.446H718.008V198.976H816.446Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M698.32 260.357H688.477V280H698.32V260.357Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M757.383 250.536H747.539V260.357H757.383V250.536Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M737.695 250.536H727.852V260.357H737.695V250.536Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M757.383 240.715H747.539V250.536H757.383V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M737.695 240.715H727.852V250.536H737.695V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M796.758 250.536H786.914V260.357H796.758V250.536Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M777.07 250.536H767.227V260.357H777.07V250.536Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M796.758 240.715H786.914V250.536H796.758V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M777.07 240.715H767.227V250.536H777.07V240.715Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M693.399 184.244C700.194 184.244 705.703 178.747 705.703 171.967C705.703 165.187 700.194 159.691 693.399 159.691C686.603 159.691 681.094 165.187 681.094 171.967C681.094 178.747 686.603 184.244 693.399 184.244Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M880.188 117.952L870.344 127.773H860.5V137.594H850.656V147.415H840.812V157.236H850.656H880.188V147.415H909.719V117.952H880.188Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M870.344 157.236H840.812V280H870.344V157.236Z"
      fill="var(--shade-color, #f8f0dd)"
    />
    <path
      d="M939.251 147.415V137.594H929.407V127.773H919.563L909.719 117.952L899.875 127.773H890.031V137.594H880.188V147.415H870.344V157.236V280H949.094V157.236V147.415H939.251Z"
      fill="var(--foreground-color, #b4f5ff)"
    />
    <path
      d="M914.641 270.179H904.797V280H914.641V270.179Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M894.953 167.058H885.109V176.879H894.953V167.058Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M914.641 167.058H904.797V176.879H914.641V167.058Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M934.328 167.058H924.484V176.879H934.328V167.058Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M894.953 186.699H885.109V196.52H894.953V186.699Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M914.641 186.699H904.797V196.52H914.641V186.699Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M934.328 186.699H924.484V196.52H934.328V186.699Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M894.953 206.341H885.109V216.162H894.953V206.341Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M914.641 206.341H904.797V216.162H914.641V206.341Z"
      fill="var(--detail-color, #2b2859)"
    />
    <path
      d="M934.328 206.341H924.484V216.162H934.328V206.341Z"
      fill="var(--detail-color, #2b2859)"
    />
  </svg>
</template>

<script>
export default {
  name: 'BuildingsGraphic',

  props: {
    skin: {
      type: String,
      default: 'info',
      validator: (value) => ['info', 'success', 'warning', 'error'].includes(value),
    },
  },
};
</script>

<style lang="scss" scoped>
.buildings {
  &--info {
    --shade-color: var(--color-beige-light);
    --foreground-color: var(--color-blue-light);
    --detail-color: var(--color-blue-dark);
  }

  &--success {
    --shade-color: var(--color-beige-light);
    --foreground-color: var(--color-green);
    --detail-color: var(--color-green-dark);
  }

  &--warning {
    --shade-color: var(--color-beige-light);
    --foreground-color: var(--color-yellow);
    --detail-color: var(--color-grayscale-60);
  }

  &--error {
    --shade-color: var(--color-red-30);
    --foreground-color: var(--color-red);
    --detail-color: var(--color-grayscale-50);
  }
}
</style>
